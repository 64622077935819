import React from "react";
import NewsTopbar from "components/newsCard/newsTopbar";
import { useWindowSize } from "api/commonConstants";
import { Col } from "react-bootstrap";
import dynamic from "next/dynamic";
// import ReadNews from "components/newsCard/readNews";
// import GoogleAdContainer from "../adContainer/googleAdContainer";
import ReadNewsCardNew from "../../newsCard/ReadNewsCardNew/index";

import { useRouter } from "next/router";
const TopShowsWidget = dynamic(() =>
  import("../topShows/topShowsWidget", { ssr: true })
);
const NewsImageTextBanner1 = dynamic(
  () =>
    import("components/newsCard/newsImageTextBanner/newsImageTextBanner1.js"),
  { ssr: true }
);

const FirstCategory = ({
  categoryInfo,
  newsData,
  topShowsWidgetData,
  topPodcastData,
}) => {
  const size = useWindowSize();
  console.log("categoryInfocategoryInfo", categoryInfo);
  const router = useRouter();

  return (
    <>
      <div className="outer-containers1">
        <div className="content-container">
          <div className="content">
            <div className="news-card-box">
              <NewsTopbar
                newsEn={categoryInfo?.name}
                newsHn={categoryInfo?.nameHn || ""}
                newsIcon={""}
                newsBgclassName={"bar-bg-1"}
                categoryInfo={categoryInfo}
                // horizontalPosts={categorizedNews.horizontalPosts}
                // restPosts={categorizedNews.restPosts}
              />
            </div>
            <div className="split-left-container">
              <div className="left-area-Col">
                <div style={{}}>
                  {newsData?.slice(0, 1)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <NewsImageTextBanner1 postData={postData} />
                    </Col>
                  ))}
                  {/* <ReadNews postData={featuredPosts[0].post} isBanner={true} /> */}
                </div>
                {newsData?.slice(1, 3)?.map((postData, index) => (
                  <Col key={`${postData?._id}`} className="featuredCards">
                    <ReadNewsCardNew
                      key={`index_${index}_${postData?._id}`}
                      postData={postData}
                      isBanner={false}
                    />
                  </Col>
                ))}
              </div>
              <div className="left-area-Col">
                <div style={{}}>
                  {newsData?.slice(3, 4)?.map((postData) => (
                    <Col key={`${postData?._id}`}>
                      <NewsImageTextBanner1 postData={postData} />
                    </Col>
                  ))}
                </div>
                {newsData?.slice(4, 6)?.map((postData, index) => (
                  <Col key={`${postData?._id}`} className="featuredCards">
                    <ReadNewsCardNew
                      key={`index_${index}_${postData?._id}`}
                      postData={postData}
                      isBanner={false}
                    />
                  </Col>
                ))}
              </div>
            </div>
          </div>
          <div
            className="right-area-Col"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div className="news-card-box">
              {size.width > 600 && (
                <div className="container-row py-2" align="center">
                  {/* <TopBanner /> */}

                  <div className="container-row" align="center">
                    <p
                      style={{
                        fontSize: "11px",
                        color: "#b7b7b7",
                        marginBottom: "0px",
                      }}
                    >
                      Advertisement
                    </p>
                    <div
                      id={
                        router.pathname === "/"
                          ? "div-gpt-ad-1726480392574-0"
                          : "div-gpt-ad-1701945220410-0"
                      }
                      style={{
                        minHeight: router.pathname === "/" ? "100px" : "260px",
                        alignContent: "center",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                          __html:
                            router.pathname === "/"
                              ? `
                          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1726480392574-0')})`
                              : `
                          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-0')})`,
                        }}
                      ></script>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {
              <div className="checkDesktopOrNot">
                <div
                  className="line-newsImageTextBanner1"
                  style={{
                    height: "1px",
                    background: "rgba(166, 166, 166, 0.2)",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                ></div>

                {newsData?.slice(6, 8)?.map((postData, index) => (
                  <Col key={`${postData?._id}`} className="featuredCards">
                    <ReadNewsCardNew
                      key={`index_${index}_${postData?._id}`}
                      postData={postData}
                      isBanner={false}
                    />
                  </Col>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
      {/* {!router.pathname.includes("/text") && (
        <GoogleAdContainer
          divId={
            size.width > 600
              ? "div-gpt-ad-1701945220410-1"
              : "div-gpt-ad-1701946028239-1"
          }
        />
      )} */}
      {(router.asPath.split("?")[0] === "/" ||
        router.asPath.split("?")[0] === "/video") && (
        <TopShowsWidget
          TopShowsWidgetData={topShowsWidgetData}
          topPodcastData={topPodcastData}
        />
      )}
      {size.width > 500 && (
        <div className="container-row py-2" align="center">
          <p
            style={{ fontSize: "11px", color: "#b7b7b7", marginBottom: "0px" }}
          >
            Advertisement
          </p>
          <div
            id={
              size.width > 600
                ? router.asPath.split("?")[0] === "/"
                  ? "div-gpt-ad-1701945220410-2"
                  : router.asPath.split("?")[0] === "/text"
                  ? "div-gpt-ad-1701945220410-1"
                  : "div-gpt-ad-1701945220410-2"
                : router.pathname === "/"
                ? "div-gpt-ad-1701946028239-2"
                : router.asPath.split("?")[0] === "/text"
                ? "div-gpt-ad-1701946028239-0"
                : "div-gpt-ad-1701946028239-1"
            }
            style={{
              minWidth: "300px",
              minHeight: size.width > 600 ? "100px" : "260px",
              display: "block !important",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  size.width > 600
                    ? router.asPath.split("?")[0] === "/"
                      ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-2')})`
                      : router.asPath.split("?")[0] === "/text"
                      ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-1')})`
                      : `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})`
                    : router.pathname === "/"
                    ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-2')})
              `
                    : router.asPath.split("?")[0] === "/text"
                    ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})
              `
                    : `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})
              `,
              }}
            ></script>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstCategory;
